import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { CommonHeaderComponent } from '../common-component/common-header/common-header.component';
import { CommonFooterComponent } from '../common-component/common-footer/common-footer.component';
// Material module start
import {MatExpansionModule} from '@angular/material/expansion';
import {MatIconModule} from '@angular/material/icon';
import {MatMenuModule} from '@angular/material/menu';
import {MatBadgeModule} from '@angular/material/badge';
import {MatButtonModule} from '@angular/material/button';
import {MatNativeDateModule, MatRippleModule} from '@angular/material/core';
import {MatTabsModule} from '@angular/material/tabs';
import {MatFormFieldModule} from '@angular/material/form-field';
import {MatInputModule} from '@angular/material/input';
import {MatSelectModule} from '@angular/material/select';
import {MatTooltipModule} from '@angular/material/tooltip';
import {MatDatepickerModule} from '@angular/material/datepicker';
import {MatSlideToggleModule} from '@angular/material/slide-toggle';
import {MatCheckboxModule} from '@angular/material/checkbox';
import { FormsModule } from '@angular/forms';
import { NgSelectModule } from '@ng-select/ng-select'; // angular dropdown
import {MatStepperModule} from '@angular/material/stepper';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import {MatRadioModule} from '@angular/material/radio';
import {MatDividerModule} from '@angular/material/divider';
import { NgxPaginationModule }  from 'ngx-pagination';
// Material module end
// search module
// import { Ng2SearchPipeModule } from 'ng2-search-filter';



// sliac


@NgModule({
  declarations: [
    CommonHeaderComponent, //header component share
    CommonFooterComponent,  //footer component share
  ],
  imports: [
    CommonModule,
    // Material module start
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatRippleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
    MatStepperModule,
    SlickCarouselModule,
    MatDividerModule,
    NgSelectModule,
    NgxPaginationModule
    // Material module end
    // search
    // Ng2SearchPipeModule
  ],
  exports: [
    CommonHeaderComponent, //header component share
    CommonFooterComponent, //footer component share
    // Material module start
    MatExpansionModule,
    MatIconModule,
    MatMenuModule,
    MatBadgeModule,
    MatButtonModule,
    MatRippleModule,
    MatTabsModule,
    MatFormFieldModule,
    MatInputModule,
    MatSelectModule,
    MatTooltipModule,
    MatDatepickerModule,
    MatNativeDateModule,
    MatSlideToggleModule,
    MatCheckboxModule,
    FormsModule,
    MatRadioModule,
    MatStepperModule,
    SlickCarouselModule,
    MatDividerModule,
    // Material module end
    NgSelectModule,
     // search
    //  Ng2SearchPipeModule
    NgxPaginationModule
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class SharedModule { }
