import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { CommonUtils } from 'src/app/services/common-utils/common-utils';
import { Subscription } from 'rxjs';
import { environment } from 'src/environments/environment';
import { AuthService } from 'src/app/services/auth/auth.service';


@Component({
  selector: 'common-footer',
  templateUrl: './common-footer.component.html',
  styleUrls: ['./common-footer.component.scss'],
})
export class CommonFooterComponent implements OnInit {

  constructor(private router: Router,
    private http : HttpClient,
    private commonUtils: CommonUtils,
    private ngxLoader: NgxUiLoaderService,
    private authService: AuthService,
    ) { }

  fileUrl= environment.fileUrl;

  private getmenuSubscribe:Subscription;
  userlogo;
  get_user_dtls: any;
  siteInfo = false;
  company_Data: any;

  ngOnInit() {
    // this.getsiteInfo();
    this.authService.globalparamsData.subscribe(res => {
      if(res != null || res != undefined){
        this.get_user_dtls = res;
        this.getsiteInfo();
      }
      else{
        this.getuserInfo();
      }
    });
    
  }

  gohome() {
    this.router.navigateByUrl('/home');
  }
  gocontact() {
    this.router.navigateByUrl('/contact');
  }
  goabout() {
    this.router.navigateByUrl('/privacy-policy');
  }
  getsiteInfo() {
    
      this.ngxLoader.start();
      setTimeout(() => {
         this.getmenuSubscribe = this.http.get('site_info').subscribe(
          (res:any) => {
            if(res.message == "Token has Expired" || res.message == "Token is Invalid"){
              this.logout();
            }
            if(res.return_status > 0){
              this.userlogo = res.return_data.data[0];
              this.company_Data = res.return_data.data[0];
              this.ngxLoader.stop();
            }
            else{
            this.ngxLoader.stop();
              if(res.return_message=="Token has Expired"){
                this.logout();
              }
              // this.commonUtils.presentToast('error', res.return_message); 
            }
          },
          (err) => {
            this.ngxLoader.stop();
            this.commonUtils.presentToast('error', err.return_message); 
          }
        );
      }, 500);
  }
  getuserInfo(){
    this.ngxLoader.start();
    setTimeout(() => {
        this.getmenuSubscribe = this.http.get('get-company-details').subscribe(
        (res:any) => {
          if(res.return_status > 0){
            this.userlogo = res.return_data[0];
            this.company_Data = res.return_data[0];
            this.ngxLoader.stop();
          }else{
            this.ngxLoader.stop();
            this.commonUtils.presentToast('error', res.return_message); 
          }
        },
        (err) => {
          this.ngxLoader.stop();
          this.commonUtils.presentToast('error', err.return_message); 
        }
      );
    }, 500);
  }
  logout(){
    this.authService.logout();
    localStorage.removeItem('token');
  }
  // ----------- destroy subscription start ---------
  ngOnDestroy() {
    if(this.getmenuSubscribe !== undefined){
      this.getmenuSubscribe.unsubscribe();
    }
  }
  // destroy subscription end

}
