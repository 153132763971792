// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiMaster:'888',

  // apiUrl:'https://d1e7-2401-4900-7077-31c-442d-eb9a-1fc0-8f51.ngrok-free.app/unifide_agro_v2.0/api',
  // fileUrl:'https://d1e7-2401-4900-7077-31c-442d-eb9a-1fc0-8f51.ngrok-free.app/unifide_agro_v2.0/',

  apiUrl : 'https://unifidebazar.com/admin/api',
  fileUrl : 'https://unifidebazar.com/admin/',


  // apiUrl:'http://192.168.1.7/unifide_agro_v2.0/api',
  // fileUrl:'http://192.168.1.7/unifide_agro_v2.0/',
  

    // apiUrl:'http://localhost/unifide_agro/api',
    // fileUrl:'http://localhost/unifide_agro/',
  
  // apiUrl:'http://192.168.29.25/Sadhona_Store/sadhonastores/api',
  // fileUrl:'http://192.168.29.25/Sadhona_Store/sadhonastores',
  apiKey:1
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
