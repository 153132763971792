import { Component, OnInit ,HostListener, ElementRef, ViewChild,Input} from '@angular/core';
import {MatButtonModule} from '@angular/material/button'
import {trigger,
  state,
  style,
  animate,
  transition,
  // ...
} from '@angular/animations';
import { NavController } from '@ionic/angular';
import { ActivatedRoute, Router } from '@angular/router';
import { NgForm } from '@angular/forms';
import { HttpClient } from '@angular/common/http';
import { CommonUtils } from 'src/app/services/common-utils/common-utils';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { Subscription } from 'rxjs';
import { AuthService } from 'src/app/services/auth/auth.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'common-header',
  templateUrl: './common-header.component.html',
  styleUrls: ['./common-header.component.scss'],
   animations: [
    trigger(
      'fixed_mobile', [
        transition(':enter', [
          style({transform: 'translateX(-100%)', opacity: 0}),
          animate('200ms', style({transform: 'translateX(0)', opacity: 1}))
        ]),
        transition(':leave', [
          style({transform: 'translateX(0)', opacity: 1}),
          animate('200ms', style({transform: 'translateX(-100%)', opacity: 0}))
        ])
      ]
    )
  ],
})

export class CommonHeaderComponent implements OnInit {
  @Input() count_cart: any;
  fileUrl= environment.fileUrl;

  myColor = "rgba(255,255,255,.2)"
  mobile=false;
  hidemobile_menu;
  showmobile_menu = false;
  current_url_path_name;
  navitems:any=[
    {icon:"account_circle",module_name:'Login',url:'/login/3'},
    {icon:"person_add",module_name:'Register',url:'/register/3'},
    {icon:"person_outline",module_name:'Partner',url:'/welcome/2'},
    {icon:"person_outline",module_name:'Seller',url:'/welcome/4'}
  ];
  // navitems start
  // navitems end
  id =2;
  sidebarItems: any=[];
  private getmenuSubscribe:Subscription;
  category;
  nav_items: any;
  username;
  changeText: string;
  userlogo;
  get_user_dtls: any;
  siteInfo = false;
  loader = false;
  model:any = {};
  razorpay_key: any;
  membership_status: any;
  rolesId: any;
  // count_cart: any;
  constructor(
    private navCtrl : NavController,
    private router: Router,
    private authService: AuthService,
    private activatedRoute:ActivatedRoute,
    private http : HttpClient,
    private commonUtils: CommonUtils,
    private ngxLoader: NgxUiLoaderService) { }

  match:any = [{name:"profile"}]
  ngAfterViewInit(){
    this.authService.globalparamsData.subscribe(res => {
      if(res != null || res != undefined){
        this.get_user_dtls = res;
        this.getsiteInfo();
        this.siteInfo = true;
      }
      else{
        this.getuserInfo();
        this.siteInfo = false;
      }
    });
  }

  ngOnInit() {
    this.current_url_path_name =  this.router.url.split('/')[1];
    this.changeText="Rice"
  }
  
  getsiteInfo(){
    this.ngxLoader.start();
    this.loader = true;
    setTimeout(() => {
        this.getmenuSubscribe = this.http.get('site_info').subscribe(
        (res:any) => {
          if(res.message == "Token has Expired" || res.message == "Token is Invalid"){
            this.logout();
            localStorage.removeItem('token');
          }
          if(res.return_status > 0){
            this.loader = false;
            var username = res.return_data.username.split(" ", 1);
            this.username = username;
            this.membership_status = res.return_data.membership_status
            this.userlogo = res.return_data.data[0];
            this.sidebarItems=res.return_data.menu;
            this.count_cart = res.return_data.count_cart;
            this.razorpay_key = res.return_data.RAZORPAY_KEY;
            this.rolesId = res.return_data.roles_id;
            this.ngxLoader.stop();
          }
          else{
            this.loader = false;
            this.ngxLoader.stop();
            if(res.return_message=="Token has Expired"){
              this.logout();
              localStorage.removeItem('token');
            }
            this.commonUtils.presentToast('error', res.return_message); 
          }
        },
        (err) => {
          this.loader = false;
          this.ngxLoader.stop();
          this.commonUtils.presentToast('error', err.return_message); 
        }
      );
    }, 500);
       
  }
  // get menu end
  getuserInfo(){
    this.ngxLoader.start();
    setTimeout(() => {
        this.getmenuSubscribe = this.http.get('get-company-details').subscribe(
        (res:any) => {
          if(res.return_status > 0){
            this.userlogo = res.return_data[0];
            this.ngxLoader.stop();
          }else{
            this.ngxLoader.stop();
            this.commonUtils.presentToast('error', res.return_message); 
          }
        },
        (err) => {
          this.ngxLoader.stop();
          this.commonUtils.presentToast('error', err.return_message); 
        }
      );
    }, 500);
  }

  myorder(){
    this.router.navigateByUrl('/my-orders');
  }
  subscribe(){
    this.router.navigateByUrl('/subscription');
  }
  // go to show product on search start
  onSearch(form:NgForm)
  {
    // var subId
    this.router.navigateByUrl(`search-result/${form.value.searchtext}`);
  }
  // go to show product on search end
  // go to home page start
  gohome(){
    this.router.navigateByUrl('/home');
  }
  // go to home page end
  // go to profile page start
  profile()
  {
    this.router.navigateByUrl('/profile');
  }
  // go to profile page end
  
  // go to login page start
  logout(){
    this.authService.logout();
    localStorage.removeItem('token');
  }
  // go to login page end
  // go to cart page start
  cart()
  {
    this.router.navigateByUrl('/mycart');
  }
  // go to cart page end
  // scroll event detect
  isFixedHeader;
  onScrollHedearFix(event) {
    if (event.detail.scrollTop > 35) {
      this.isFixedHeader = true;
    } else {
      this.isFixedHeader = false;
    };
  }
  // side menu start
  togglemenu()
  {
    this.showmobile_menu = !this.showmobile_menu;
  }
  // side menu end
  //  
  addClass: boolean = false;
  // go to page on click start 

  goToPage(_url, pageurl)
  {
    if(_url == '')
    {
    }
    else
    {
      this.router.navigateByUrl(pageurl);
    }
  }
  goTosubPage(_url, _item)
  {
    this.router.navigateByUrl(_url);
  }
  userRegister(){
    this.router.navigateByUrl('register/3');
  }
  userLogin(){
    this.router.navigateByUrl('login/3');
  }
  partnerLogin(roll){
    this.router.navigateByUrl('welcome/'+roll);
  }
  sellerLogin(roll){
    this.router.navigateByUrl('welcome/'+roll);
  }
  // go to page on click end
  addCart(){
    this.router.navigateByUrl('add-cart');
  }
   // ----------- destroy subscription start ---------
   ngOnDestroy() {
    if(this.getmenuSubscribe !== undefined){
      this.getmenuSubscribe.unsubscribe();
    }
  }
  // destroy subscription end
}
