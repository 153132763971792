import { Platform } from '@ionic/angular';
import { Injectable } from '@angular/core';
import { Storage } from '@ionic/storage';
import { BehaviorSubject, from } from 'rxjs';
import { take, map, tap, delay, switchMap } from 'rxjs/operators';
import { HttpClient } from '@angular/common/http';
import { Router } from '@angular/router';

import { environment } from '../../../environments/environment';
import { Location } from '@angular/common';
import { CommonUtils } from '../common-utils/common-utils';

 
const API_URL = environment.apiUrl;
const API_MASTER = environment.apiMaster;

/* tslint:disable */ 
@Injectable({
  providedIn: 'root'
})
export class AuthService {
  
  // private _globalparamsData = null;
  private _globalparamsData = new BehaviorSubject(null);

  // get token session master as observable
  get globalparamsData(){
    return this._globalparamsData.asObservable();
  }

  // get token session master as a non observable
  public getTokenSessionMaster() {

    return this._globalparamsData.value;
  }

  constructor(
    private storage: Storage, 
    private plt: Platform,
    private http : HttpClient,
    private router: Router,
    private location: Location,
    private commonUtils: CommonUtils 
  ) { 
  }
 

  //================== auto login start ===================
    autoLogin(){
      // stroage data get
      
      return from(this.storage.get('setStroageGlobalParamsData')).pipe(
        map(storData => {
          if(!storData || !storData.token){
            return null;
          }
          const storeauth = {
            'token': storData.token,
          }
          return storeauth;
        }),
        tap(storeauthtication => {
          if (storeauthtication) {
            this._globalparamsData.next(storeauthtication);
          }
        }),
        map(userLog => {
          return !!userLog;  //return true/false(boolen value)
        })
      );
    }
  // auto login end

   //----- login check for website start------
   autoLoginWebsite(){
    let autologinObsData = from(this.storage.get('setStroageGlobalParamsData'));
    this._globalparamsData.next(autologinObsData);
    return autologinObsData;
  }
  // login check for website end
  
  // ================= login service call start ==================
    login(_url, _data, _siteInfo) {
      return this.http.post(`${_url}`, _data).pipe(
        tap(this.setGlobalParams.bind(this)) //use for response value send
      );
    }
    // ---setGlobalParams function defination----
    private setGlobalParams(resData){
      if(resData.return_status > 0){
        this.commonUtils.presentToast('success', resData.return_message);
        this._globalparamsData.next( 
          {
            'token': resData.return_data,
          }
        );
        // stroage
        this.storeAuthData(resData);
      }
    }
    //--- storeAuthData function defination---
    private storeAuthData(_data) {
      // set stroage data
      this.storage.set('setStroageGlobalParamsData',  {
        'token': _data.return_data,
      });
    }
  //login service call end

  //======================= logout functionlity start ==============
    logout() {
      this.storage.clear().then(() => {
        this.router.navigateByUrl('/home');
        this._globalparamsData = new BehaviorSubject(null);
         window.location.reload(); //working
      });
    }
  // logout functionlity end
 
}