import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './services/auth/auth.guard';

const routes: Routes = [
  {
    path: '',
    redirectTo: 'home',
    pathMatch: 'full'
  },
  // {
  //   path: 'dashboard',
  //   loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
  //   // canLoad: [AuthGuard] 
  // },
  // {
  //   path: 'auth',
  //   loadChildren: () => import('./pages/auth/auth.module').then( m => m.AuthPageModule)
  // },
 
  {
    path: 'home',
    loadChildren: () => import('./pages/home/home.module').then( m => m.HomePageModule),
    // canLoad: [AuthGuard] 
  },
  // {
  //   path: 'mystock',
  //   loadChildren: () => import('./pages/mystock/mystock.module').then( m => m.MystockPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'profile-edit',
    loadChildren: () => import('./pages/profile-edit/profile-edit.module').then( m => m.ProfileEditPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'welcome/:roll',
    loadChildren: () => import('./pages/welcome/welcome.module').then( m => m.WelcomePageModule),
  },
  {
    path: 'privacy-policy',
    loadChildren: () => import('./pages/about-us/about-us.module').then( m => m.AboutUsPageModule),
    //  canLoad: [AuthGuard]
  },
  {
    path: 'addproduct',
    loadChildren: () => import('./pages/addproduct/addproduct.module').then( m => m.AddproductPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'allcategory',
    loadChildren: () => import('./pages/allcategory/allcategory.module').then( m => m.AllcategoryPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'allproducts/:category/:categoryname',
    loadChildren: () => import('./pages/allproducts/allproducts.module').then( m => m.AllproductsPageModule),
    //  canLoad: [AuthGuard]
  },
  {
    path: 'contact',
    loadChildren: () => import('./pages/contact/contact.module').then( m => m.ContactPageModule),
    //  canLoad: [AuthGuard]
  },
  {
    path: 'product-view/:id',
    loadChildren: () => import('./pages/product-view/product-view.module').then( m => m.ProductViewPageModule),
    //  canLoad: [AuthGuard]
  },
  {
    path: 'login/:roll',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    //  canLoad: [AuthGuard]
  },
  {
    path: 'register/:roll',
    loadChildren: () => import('./pages/register/register.module').then( m => m.RegisterPageModule),
    //  canLoad: [AuthGuard]
  },
  {
    path: 'dashboard',
    loadChildren: () => import('./pages/dashboard/dashboard.module').then( m => m.DashboardPageModule),
     canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
     canLoad: [AuthGuard]
  },
  // {
  //   path: 'seller-product-view',
  //   loadChildren: () => import('./pages/seller-product-view/seller-product-view.module').then( m => m.SellerProductViewPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'my-orders',
    loadChildren: () => import('./pages/my-order/my-order.module').then( m => m.MyOrderPageModule),
    canLoad: [AuthGuard] 
  },
  // {
  //   path: 'sent-orders',
  //   loadChildren: () => import('./pages/sent-orders/sent-orders.module').then( m => m.SentOrdersPageModule),
  //   canLoad: [AuthGuard]
  // },
  {
    path: 'my-products',
    loadChildren: () => import('./pages/my-products/my-products.module').then( m => m.MyProductsPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'active-products',
    loadChildren: () => import('./pages/active-products/active-products.module').then( m => m.ActiveProductsPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'inactive-products',
    loadChildren: () => import('./pages/inactive-products/inactive-products.module').then( m => m.InactiveProductsPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'view-product/:productid',
    loadChildren: () => import('./pages/view-product/view-product.module').then( m => m.ViewProductPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'import',
    loadChildren: () => import('./pages/import/import.module').then( m => m.ImportPageModule),
     canLoad: [AuthGuard] 
  },
  {
    path: 'export',
    loadChildren: () => import('./pages/export/export.module').then( m => m.ExportPageModule),
     canLoad: [AuthGuard] 
  },
  {
    path: 'export-product',
    // loadChildren: () => import('./export-product/export-product.module').then( m => m.ExportProductPageModule)
    loadChildren:()=> import('./pages/export/export-product/export-product.module').then( m => m.ExportProductPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'import-product/:productid',
    loadChildren: () => import('./pages/import-product/import-product.module').then( m => m.ImportProductPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'orders/:type',
    loadChildren: () => import('./pages/orders/orders.module').then( m => m.OrdersPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'search-result/:search',
    loadChildren: () => import('./pages/search-result/search-result.module').then( m => m.SearchResultPageModule),
    // canLoad: [AuthGuard]
  },
  {
    path: 'invoice-management',
    loadChildren: () => import('./pages/invoice-management/invoice-management.module').then( m => m.InvoiceManagementPageModule),
    canLoad: [AuthGuard] 
  
  },
  {
    path: 'add-cart',
    loadChildren: () => import('./pages/add-cart/add-cart.module').then( m => m.AddCartPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'order-placed/:productId/:pacqty/:qty/:type',
    loadChildren: () => import('./pages/order-placed/order-placed.module').then( m => m.OrderPlacedPageModule),
    canLoad: [AuthGuard] 
  },
  {
    path: 'subscription',
    loadChildren: () => import('./pages/subscription/subscription.module').then( m => m.SubscriptionPageModule)
  },
  {
    path: 'subscription-policy/:id',
    loadChildren: () => import('./pages/subscription-policy/subscription-policy.module').then( m => m.SubscriptionPolicyPageModule)
  }



];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
